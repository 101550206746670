import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification mdxType="InlineNotification">
      <p>{`Legion UI package is currently only available as a private package for Telkom Indonesia internal products.`}</p>
    </InlineNotification>
    <br />
    <h2>{`Prequisites`}</h2>
    <p>{`Already have at least these below:`}</p>
    <ul>
      <li parentName="ul">{`Nodejs v16 or above, if you are using multiple versions of node, we recommend using nvm to manage its version`}</li>
      <li parentName="ul">{`Node Package Manager v8 or above`}</li>
      <li parentName="ul">{`Yarn v1.22.19 or above`}</li>
    </ul>
    <div className="divi" />
    <h2>{`Installation`}</h2>
    <h3><strong parentName="h3">{`Proxy and Authentication`}</strong></h3>
    <p>{`Since Legion V2, we move out and retire the older package manager that uses Nexus and move in to Package & Registries built in from our private GitLab.`}</p>
    <p>{`Other changes occurred is having it scoped now, rather than doing an infrastructure level installation.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create `}<inlineCode parentName="p">{`.npmrc`}</inlineCode>{` file to your project.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Follow the step `}<a parentName="p" {...{
            "href": "https://legion-dev.vercel.app/contribute-and-request#request-access-design-or-code-library"
          }}>{`here`}</a>{` in order to create your authorization token needed in the `}<inlineCode parentName="p">{`.npmrc`}</inlineCode>{`, otherwise, you can skip this step and continue to step number 3.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Write down following script regarding package authorization:`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`@legion-ui:registry=XXXXXXXXXXXX
always-auth=true
//gitlab.playcourt.id/api/v4/projects/21411/packages/npm/:_authToken="XXXXXXXXXXXX"
`}</code></pre>
    <p>{`Fill the `}<inlineCode parentName="p">{`XXXXXXXXXXXX`}</inlineCode>{` with given credentials you receive from the request process.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`After that with either one of your package manager.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# npm
npm install @emotion/react @emotion/styled @legion-ui/core @legion-ui/dates

# yarn
yarn add @emotion/react @emotion/styled @legion-ui/core @legion-ui/dates
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      